export default [
  {
    title: 'Inicio',
    path: '/',
    icon: 'home',
    show: true,
    description: '',
  },
  {
    title: 'Gestión de usuarios',
    path: '/dashboard/users/',
    icon: 'user',
    show: true,
    description: '',
  },
  {
    title: 'Gestión de entidades',
    path: '/dashboard/companies',
    icon: 'bank',
    show: true,
    description: '',
  },
  {
    title: 'Gestión de quejas',
    path: '/dashboard/complaints/',
    icon: 'solution',
    show: true,
    description: '',
  },
  {
    title: 'Radicar una nueva queja',
    path: '/dashboard/complaints/complaint/',
    icon: 'form',
    show: true,
    description: '',
  },
  {
    title: 'Carga masiva de quejas',
    path: '/dashboard/complaints/upload/',
    icon: 'cloud-upload',
    show: true,
    description: '',
  },
  {
    title: 'Análitica e indicadores',
    path: '/dashboard/analytics/',
    icon: 'bar-chart',
    show: true,
    description: '',
  },
  {
    title: 'Sincronizar',
    path: '/dashboard/sync/',
    icon: 'sync',
    show: true,
    description: '',
  },
  {
    description: 'Encuentra acceso directo los tarifarios.',
    icon: 'file-text',
    path: '/dashboard/tariffs',
    show: true,
    title: 'Tarifario',
  },
]
