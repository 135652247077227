<template>
  <nav class="h-20 py-3 w-full">
    <div class="flex justify-between items-center">
      <div class="flex items-center">
        <div
          v-if="collapsed && mobile"
          class="h-full mr-auto text-md text-primary text-left"
        >
          <div
            class="p-3 flex items-center cursor-pointer rounded-lg bg-white shadow"
            @click="toggleSidebar"
          >
            <a-icon type="menu" :style="{ fontSize: '22px' }" />
          </div>
        </div>
        <div class="h-full hidden sm:block px-5 py-1 text-left rounded-full">
          <h2 class="text-left text-3xl text-gray-600">
            <b>{{ title }}</b>
          </h2>
          <Breadcrumb />
        </div>
      </div>

      <div v-if="collapsed" class="flex items-center gap-4">
        <NotificationMenu />
        <a-dropdown
          v-if="user"
          placement="bottomRight"
          :trigger="['click']"
          class="cursor-pointer"
          :getPopupContainer="(trigger) => trigger.parentNode"
        >
          <div
            class="py-3 px-3 flex justify-center items-center rounded-lg bg-white shadow"
          >
            <div class="block mx-1">
              <b class="mx-2 p-0 text-gray-600">Mi cuenta</b>
            </div>
            <a-icon type="caret-down" class="text-md text-gray-600" />
          </div>
          <a-menu slot="overlay">
            <a-menu-item key="1"
              ><router-link to="/dashboard/profile" class="inline" append
                ><a-icon type="user" class="mr-2" />Perfil</router-link
              ></a-menu-item
            >
            <a-menu-item key="2"
              ><router-link to="/dashboard/notifications" class="inline" append
                ><a-icon
                  type="message"
                  class="mr-2"
                />Notificaciones</router-link
              ></a-menu-item
            >
            <a-menu-divider />
            <a-menu-item key="3" @click="logout">
              <a-icon type="close" />Cerrar sesión
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>
  </nav>
</template>

<script>
import NotificationMenu from '@/components/UI/Notifications/NotificationMenu'
import Breadcrumb from '@/components/UI/Breadcrumb'
export default {
  props: ['mobile', 'collapsed'],
  components: {
    NotificationMenu,
    Breadcrumb,
  },
  methods: {
    logout() {
      this.$store
        .dispatch('session/logout')
        .then(() => this.$router.push('/login'))
    },
    toggleSidebar() {
      if (this.mobile) {
        this.$emit('toggle-sidebar')
      }
    },
  },
  computed: {
    title: function () {
      return `${this.$route.meta.title || 'Titulo'} ${
        this.$route.query[this.$route.meta.param] || ''
      }`
    },
    user: function () {
      return this.$store.state.session.user
    },
  },
}
</script>

<style></style>
