<template>
  <div>
    <div v-for="(item, index) in menu" :key="index">
      <router-link
        v-if="item.show"
        :to="item.path"
        :class="item.path === $route.path ? 'router-link-exact-active' : ''"
        class="my-3 py-3 pl-8 flex justify-between items-center text-white hover:bg-gray-100 hover:bg-opacity-20 hover:text-white hover:font-bold"
        @click.native="$emit('toggle-sidebar')"
      >
        <span class="flex items-start justify-center gap-3">
          <a-icon
            :type="item.icon"
            :style="{ fontSize: '20px' }"
            class="font-normal"
          />
          {{ item.title }}
        </span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menu: {
      type: Array,
    },
  },
}
</script>

<style></style>
