<template>
  <div
    v-if="me"
    id="dashboard-sidemenu"
    class="h-full w-full py-5 flex flex-col justify-between text-white bg-primary"
    :class="responsiveClasses"
  >
    <div v-if="!mobile" class="mx-auto w-3/4 mt-2 mb-6 pb-3 flex flex-col">
      <div
        style="padding-top: 29.51%"
        class="w-full relative bg-primary overflow-hidden"
      >
        <div class="absolute top-0 w-full h-full">
          <img
            v-if="me.company && companyLogo"
            :src="companyLogo"
            class="w-full h-full"
            alt=""
          />
          <LogoFake v-else class="w-full h-full" />
        </div>
      </div>
    </div>
    <div
      v-else
      class="w-full mt-5 mb-5 pr-5 flex justify-end text-left cursor-pointer"
      @click="toggleSidebar"
    >
      <a-icon
        v-if="!collapsed"
        type="arrow-left"
        :style="{ fontSize: '20px' }"
      />
    </div>
    <div
      v-if="!collapsed || !mobile"
      class="sidemenu-container relative flex-1 overflow-auto"
    >
      <div class="absolute h-full w-full text-left">
        <b class="pl-8">Secciones</b>
        <SidebarMenu :menu="menu" @toggle-sidebar="toggleSidebar" />
      </div>
    </div>
    <div
      v-if="!mobile || !collapsed"
      class="w-full mb-2 mt-5 pl-8 px-2 pt-5 border-t-2 text-left"
    >
      <span class="flex items-center gap-2 cursor-pointer" @click="logout">
        <a-icon
          type="export"
          :style="{ fontSize: '20px' }"
          class="font-normal"
        />
        Cerrar sesión
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SidebarMenu from './SidebarMenu.vue'
import LogoFake from '../../Brand/LogoFake.vue'
import menuList from './sidemenu.links'
export default {
  props: ['collapsed', 'mobile'],
  components: { LogoFake, SidebarMenu },
  data() {
    return {
      companyLogo: '',
    }
  },
  computed: {
    ...mapState('session', ['me']),
    menu() {
      let menu = []
      this.$store.state.session?.me.modules?.forEach((item) => {
        menu.push({
          title: item.title,
          path: item.path || '',
          icon: item.icon || 'file-unknown',
          show: true,
        })
      })
      let test = false
      return test ? menuList : menu
    },
    responsiveClasses() {
      if (this.mobile && this.collapsed) {
        return 'hidden'
      }
      return 'block'
    },
  },
  watch: {
    me: {
      handler(value) {
        if (!value) return
        this.$getFirebaseLink(value.company.logo).then((url) => {
          this.companyLogo = url
        })
      },
      immediate: true,
    },
  },
  methods: {
    logout() {
      this.$store
        .dispatch('session/logout')
        .then(() => this.$router.push('/login'))
    },
    toggleSidebar() {
      if (this.mobile) {
        this.$emit('toggle-sidebar')
      }
    },
  },
}
</script>

<style></style>
