<template>
  <div
    v-if="breadcrumbList"
    class="w-full pb-2 flex items-end text-md text-left"
  >
    <a-icon
      type="home"
      theme="filled"
      class="my-auto mr-2 opacity-90"
      :style="{ fontSize: '10px' }"
    />
    <div
      class="text-xs md:text-sm mr-1"
      v-for="(breadcrumb, index) in breadcrumbList"
      :key="index"
    >
      <span
        :class="
          index != Object.keys(breadcrumbList).length - 1
            ? ''
            : 'font-semibold text-primary'
        "
      >
        <router-link
          v-if="breadcrumb.link"
          class="underline hover:text-primary"
          :to="breadcrumb.link"
          >{{ breadcrumb.label }}</router-link
        >
        <span v-else :to="breadcrumb.link">{{ breadcrumb.label }}</span>
        <span v-if="breadcrumb.param">
          {{ $route.query[breadcrumb.param] }}</span
        >
      </span>
      <span
        v-if="index != Object.keys(breadcrumbList).length - 1"
        class="mx-px"
      >
        /
      </span>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.breadcrumbList = this.$route.meta.breadcrumb
  },
  data() {
    return {
      breadcrumbList: [],
    }
  },
  watch: {
    $route() {
      this.breadcrumbList = this.$route.meta.breadcrumb
    },
  },
}
</script>

<style></style>
