<template>
  <svg
    width="139"
    height="51"
    viewBox="0 0 139 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  ></svg>
</template>

<script>
export default {}
</script>

<style></style>
