<template>
  <span
    class="h-auto py-3 px-4 flex items-center bg-white text-white rounded-lg font-bold shadow"
  >
    <a-dropdown
      placement="bottomRight"
      :trigger="['click']"
      @visibleChange="openDropdown"
    >
      <span class="relative pt-px mr-px h-5 w-5 cursor-pointer"
        ><img src="@/assets/icons/bell.svg" alt="" class="w-full h-auto" />

        <span class="absolute text-xs px-1 -top-1 bg-secondary rounded-full">{{
          unreadNotifications
        }}</span></span
      >
      <a-menu v-if="notifications.length > 0" slot="overlay">
        <a-menu-item v-for="(item, index) in notifications" :key="index">
          <div class="max-w-lg">
            <NotificationCardNavbar
              :notification="item"
              class="max-w-lg"
              :class="item.path ? 'cursor-pointer' : ''"
              @click.native="goTo(item)"
            />
          </div>
        </a-menu-item>
        <a-menu-item class="border-t border-solid border-gray-100">
          <router-link to="/dashboard/notifications">
            <span class="block font-bold text-center text-xs text-primary"
              >Ver todas la notificaciones</span
            >
          </router-link>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
  </span>
</template>

<script>
import { getAuth } from 'firebase/auth'
import { getDatabase, ref, onValue, set } from 'firebase/database'
import NotificationCardNavbar from '@/components/UI/Notifications/NotificationCardNavbar'
export default {
  components: {
    NotificationCardNavbar,
  },
  data() {
    return {
      notifications: [],
      unreadNotifications: 0,
    }
  },
  mounted() {
    const auth = getAuth()
    const user = auth.currentUser
    const db = getDatabase()
    const notificationRef = ref(db, 'users/' + user.uid)
    onValue(notificationRef, (snapshot) => {
      const data = snapshot.val()
      if (data) {
        const notifications = Object.values(data)
        let readNotifications = 0
        notifications.forEach((notification) => {
          if (notification.read) return
          readNotifications++
        })
        this.unreadNotifications = readNotifications
        this.notifications = notifications
          .slice(-5)
          .map((value) => value)
          .reverse()
      }
    })
  },
  methods: {
    openDropdown(open) {
      if (!open) return
      const auth = getAuth()
      const user = auth.currentUser
      const db = getDatabase()

      for (let notification of this.notifications) {
        if (notification.read) return
        set(ref(db, 'users/' + user.uid + '/' + notification.id), {
          ...notification,
          read: true,
        })
      }
    },
    goTo(notification) {
      if (!notification.path) return
      this.$router.push(notification.path)
    },
  },
}
</script>

<style></style>
