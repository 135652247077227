<template>
  <div
    class="h-screen max-h-screen max-w-full flex flex-col justify-start items-center bg-gray-50"
  >
    <div class="relative flex-1 w-full max-w-full flex flex-row">
      <Sidebar
        class="h-full max-w-xs max-h-full"
        @toggle-sidebar="toggleSidebar"
        :collapsed="collapsed"
        :mobile="mobile"
        :class="{
          'absolute flex-grow z-50': !collapsed && mobile,
        }"
      />
      <div
        class="relative h-full max-h-full w-full ml-5 pt-5 pr-5 overflow-y-auto overflow-x-hidden"
        :class="{
          'opacity-0': !collapsed && mobile,
        }"
      >
        <Navbar
          class="mb-5"
          :collapsed="collapsed"
          :mobile="mobile"
          @toggle-sidebar="toggleSidebar"
        />
        <div class="absolute w-full h-full pr-5">
          <div class="w-full py-5">
            <transition name="fade-in">
              <router-view></router-view>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/UI/Navbar/Navbar'
import Sidebar from '@/components/UI/Sidebar/Sidebar'
export default {
  components: {
    Navbar,
    Sidebar,
  },
  data() {
    return {
      collapsed: true,
      mobile: true,
    }
  },
  created() {
    this.setMobile()
    window.addEventListener('resize', this.setMobile)
  },
  methods: {
    toggleSidebar() {
      this.collapsed = !this.collapsed
    },
    setMobile() {
      if (window.innerWidth < 1024) {
        this.mobile = true
      } else {
        this.mobile = false
      }
    },
  },
}
</script>

<style></style>
